import domReady from '@wordpress/dom-ready';

domReady(() => {
  const buttons = document.querySelectorAll('.wp-block-button__link');

  if (0 < buttons.length) {
    buttons.forEach((button) => {
      const btnText = button.textContent;
      const btnInnerContent = document.createElement('span');
      btnInnerContent.classList.add('btn__text');
      btnInnerContent.innerHTML =
        '<span class="text-main">' +
        btnText +
        '</span><span class="text-hover" aria-hidden="true">' +
        btnText +
        '</span>';
      button.replaceChild(btnInnerContent, button.firstChild);
    });
  }
});
